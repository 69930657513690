import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form/dist/react-hook-form.ie11';
import ReactTooltip from 'react-tooltip';

import Info from '../../svg/info.svg';

const Select = ({
  full,
  label,
  name,
  options,
  validation = {},
  style,
  tooltip,
}) => {
  const { errors, register } = useFormContext();

  const renderOptions = () => (
    <>
      {options.map((option, key) => (
        <option key={key} value={option.label}>
          {option.label}
        </option>
      ))}
    </>
  );

  return (
    <div className={classNames(`Select`, { full })} style={style}>
      <ReactTooltip effect="solid" />
      <label htmlFor={name}>
        <div
          style={{
            display: `flex`,
            alignItems: `center`,
          }}
        >
          <div
            style={{
              marginRight: 10,
            }}
          >
            {label}
          </div>
          {tooltip && (
            <div
              data-tip={tooltip}
              style={{
                cursor: `pointer`,
              }}
            >
              <Info
                style={{
                  width: 16,
                  height: 16,
                  fill: `hsl(210, 95%, 22%)`,
                }}
              />
            </div>
          )}
        </div>
      </label>
      <select name={name} ref={register(validation)}>
        {renderOptions()}
      </select>
      <small>{errors[name] && errors[name].message}</small>
    </div>
  );
};

Select.defaultProps = {
  area: false,
  full: false,
  type: `text`,
};

Select.propTypes = {
  area: PropTypes.bool,
  full: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
};

export default Select;
