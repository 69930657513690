import React from 'react';

import Form from '../components/contact/form';
import Map from '../components/contact/map';
import SEO from '../components/helpers/seo';
import Layout from '../components/layout';
import useTranslation from '../hooks/useTranslation';

const Contact = () => {
  const t = useTranslation();

  return (
    <Layout
      pages={{
        en: `/en/contact`,
        fr: `/fr/contact`,
      }}
    >
      <SEO title="Contact" />
      <section className="Contact">
        <div className="container">
          <h1>{t(`contact.title`)}</h1>
          <div className="Contact__container">
            <Map />
            <Form />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Contact;
