import React, { useState } from 'react';
import useForm, {
  FormContext,
} from 'react-hook-form/dist/react-hook-form.ie11';
import allCountry from '../../data/countries';
import useTranslation from '../../hooks/useTranslation';
import slugify from 'slugify';

import Input from '../helpers/input';
import Select from '../helpers/select';

const countries = allCountry.map(country => ({
  label: country.label,
  value: slugify(country.label, {
    lower: true,
    strict: true,
  }),
}));

const required = {
  required: `Ce champ est requis.`,
};

const Form = () => {
  const [valid, setValid] = useState(false);
  const t = useTranslation();

  const methods = useForm({
    mode: `onBlur`,
  });

  const onSubmit = methods.handleSubmit((data, e) => {
    console.log(data);
    e.target.reset();
    methods.reset();
  });

  return (
    <section className="Contact__form">
      <FormContext {...methods}>
        <form onSubmit={onSubmit}>
          <Input
            label={t(`form.firstName`)}
            name="firstName"
            validation={required}
          />
          <Input
            label={t(`form.lastName`)}
            name="lastName"
            validation={required}
          />
          <Input
            label={t(`form.email`)}
            name="email"
            type="email"
            validation={required}
          />
          <Input label={t(`form.phone`)} name="phone" validation={required} />
          <Select
            options={countries}
            label={t(`form.place`)}
            name="place"
            style={{
              flex: `0 0 100%`,
            }}
            validation={required}
            tooltip={t(`contact.info`)}
          />
          <Input
            area
            full
            label={t(`form.message`)}
            name="message"
            validation={required}
          />
          <div className="Booking__checkbox">
            <div>
              <input
                id="valid"
                type="checkbox"
                onChange={() => setValid(!valid)}
                value={valid}
                checked={valid}
              />
            </div>
            <label htmlFor="valid">{t(`booking.accept`)}</label>
          </div>
          <div className="Contact__form__submit">
            <input type="submit" value={t(`form.send`)} disabled={!valid} />
          </div>
        </form>
      </FormContext>
    </section>
  );
};

export default Form;
