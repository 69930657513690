import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Map = () => {
  const { map } = useStaticQuery(query);

  return (
    <section className="Contact__map">
      <div className="Contact__map__container">
        <Img fluid={map.childImageSharp.fluid} />
      </div>
    </section>
  );
};

const query = graphql`
  {
    map: file(relativeDirectory: { eq: "contact" }, name: { eq: "map" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 600, cropFocus: ENTROPY, fit: COVER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Map;
