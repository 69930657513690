import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form/dist/react-hook-form.ie11';

const Input = ({
  area,
  full,
  label,
  name,
  type,
  validation = {},
  value = ``,
}) => {
  const { errors, register, setValue } = useFormContext();

  useEffect(() => {
    if (value || value.length > 0) {
      setValue(name, value);
    }
  }, [value]);

  return (
    <div className={classNames(`Input`, { full })}>
      <label htmlFor={name}>{label}</label>
      {!area ? (
        <input
          id={name}
          type={type}
          name={name}
          ref={register(validation)}
          defaultValue={value}
        />
      ) : (
        <textarea
          id={name}
          name={name}
          ref={register(validation)}
          rows={10}
          defaultValue={value}
        />
      )}
      <small>{errors[name] && errors[name].message}</small>
    </div>
  );
};

Input.defaultProps = {
  area: false,
  full: false,
  type: `text`,
};

Input.propTypes = {
  area: PropTypes.bool,
  full: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
};

export default Input;
